import { BrowserModule } from '@angular/platform-browser';
// import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InterceptorService } from './services/interceptors/interceptor.service';
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { RouterModule } from '@angular/router';

registerLocaleData(localeEs, "es");

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // RouterModule.forRoot(appRoutes, routerConfig),
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    { provide: 'BASE_URL', useFactory: getBaseUrl }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
export function getBaseUrl() {
  //     if(!environment.production){
  //    //  return  'https://g9c0phwqoi.execute-api.us-east-1.amazonaws.com/Prod/api/';
  //     return 'http://localhost:60915/api/';
  //     }
  return environment.baseUrl;
}