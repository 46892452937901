import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService extends CrudService<any>  {

  constructor(
    private config: AppConfigService,
    http: HttpClient
  ) {
    super(config.S3Url, http);
  }
  getImages(date: string) {
    let url = `${this.endpoint}/Images?day=${date}`;
    return this.http.get<any>(url);
  }
  getLink(path: string) {
    let url = `${this.endpoint}/GetLink?ruta=${path}`;
    return this.http.get<any>(url);
  }
}
