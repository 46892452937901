<section class="page-section" id="contact">
    <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-8 col-xl-6 text-center">
                <h2 class="mt-0">Contáctanos</h2>
                <hr class="divider" />
                <p class="text-muted mb-5">Si tienes cualquier duda o necesitas ayuda, ponte en contacto con nosotros
                </p>
            </div>
        </div>
        <div class="row  justify-content-center mb-5">
            <div style="display: flex; justify-content: center;">



                <div class="fb-page" data-href="https://www.facebook.com/EnCajemeJuntosPorElAutismo" data-tabs="timeline"
                data-width="1000"  data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"
                    data-show-facepile="true">
                    <blockquote cite="https://www.facebook.com/facebook" class="fb-xfbml-parse-ignore"><a
                            href="https://www.facebook.com/EnCajemeJuntosPorElAutismo">Facebook</a></blockquote>
                </div>


                <!-- <div class="fb-page " style="width: 100% !important;"
                    data-href="https://www.facebook.com/EnCajemeJuntosPorElAutismo" data-tabs="timeline"
                    data-width="1000" data-height="" data-small-header="true" data-adapt-container-width="true"
                    data-hide-cover="false" data-show-facepile="true">
                    <blockquote style="width: 1000px !important;"
                        cite="https://www.facebook.com/EnCajemeJuntosPorElAutismo" class="fb-xfbml-parse-ignore"><a
                            href="https://www.facebook.com/EnCajemeJuntosPorElAutismo">Despertando del Autismo</a>
                    </blockquote>
                </div> -->
                <!-- * * * * * * * * * * * * * * *-->
                <!-- * * SB Forms Contact Form * *-->
                <!-- * * * * * * * * * * * * * * *-->
                <!-- This form is pre-integrated with SB Forms.-->
                <!-- To make this form functional, sign up at-->
                <!-- https://startbootstrap.com/solution/contact-forms-->
                <!-- to get an API token!-->
                <!-- <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                        <div class="form-floating mb-3">
                            <input class="form-control" id="name" type="text" placeholder="Enter your name..." data-sb-validations="required" />
                            <label for="name">Nombre</label>
                            <div class="invalid-feedback" data-sb-feedback="name:required">El Nombre es requerido</div>
                        </div>
                        <div class="form-floating mb-3">
                            <input class="form-control" id="email" type="email" placeholder="name@example.com" data-sb-validations="required,email" />
                            <label for="email">Correo Electrónico</label>
                            <div class="invalid-feedback" data-sb-feedback="email:required">El correo electrónico es requerido</div>
                            <div class="invalid-feedback" data-sb-feedback="email:email">Formato de correo electrónico no válido</div>
                        </div>
                        <div class="form-floating mb-3">
                            <input class="form-control" id="phone" type="tel" placeholder="(123) 456-7890" data-sb-validations="required" />
                            <label for="phone">Teléfono</label>
                            <div class="invalid-feedback" data-sb-feedback="phone:required">El Teléfonoes requerido</div>
                        </div>
                        <div class="form-floating mb-3">
                            <textarea class="form-control" id="message" type="text" placeholder="Mensaje..." style="height: 10rem" data-sb-validations="required"></textarea>
                            <label for="message">Message</label>
                            <div class="invalid-feedback" data-sb-feedback="message:required">Mensaje requerido</div>
                        </div>
                        <div class="d-none" id="submitSuccessMessage">
                            <div class="text-center mb-3">
                                <div class="fw-bolder">Enviado correctamente!</div>
                                
                                <br />
                                <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                            </div>
                        </div>
                        <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error al enviar mensaje</div></div>
                        <div class="d-grid"><button class="btn btn-primary btn-xl disabled" id="submitButton" type="submit">Enviar</button></div>
                    </form> -->
            </div>
        </div>
        <!-- <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-4 text-center mb-5 mb-lg-0">
                <i class="bi-phone fs-2 mb-3 text-muted"></i>
                <div>644 135 5974</div>
            </div>

        </div> -->
    </div>
</section>