<!-- Masthead-->
<header class="masthead">
  <div class="container px-4 px-lg-5 h-100">
    <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
      <div class="col-lg-8 align-self-end widthMO">
        <h1 class="text-white font-weight-bold">Manos a la Obra por el Autismo </h1>
        <hr class="divider" />
      </div>
      <div class="col-lg-8 align-self-baseline">
        <p class="text-white-75 mb-5">Somos un proyecto educacional que trabaja para el despertar de la
          conciencia, empatía y respeto.</p>
        <a class="btn btn-primary btn-xl media"
          href="https://www.youtube.com/embed/F5zuytn2XQI"
          data-toggle="lightbox">Ver más</a>
      </div>
    </div>
  </div>
</header>