<div class="row" style="display: flex; justify-content: center;width: 98% !important;">
    <select id="sele" class="form-select" style="width: 200px;" (change)="seleccionCambiada($event)">
        <option selected>Seleccionar fecha</option>
        <option [value]="item" *ngFor="let item of lstCarpetas">
            {{item}}
        </option>
    </select>
</div>
<div class="row" style="width: 98% !important; text-align: center;">
    <h1>{{txtDescripcion}}</h1>
</div>
<app-slider-images [path]="objPath" style="width: 98% !important;"></app-slider-images>