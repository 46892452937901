import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {
  /**
   *
   */
  trustedDashboardUrl = null;
  constructor(
    private sanitizer: DomSanitizer
  ) {
    // this.trustedDashboardUrl =
    //   this.sanitizer.bypassSecurityTrustResourceUrl
    //     ("URL");
  }
  ngOnInit(): void {

  }
}
