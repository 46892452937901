<section class="page-section bg-primary" id="about">
    <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-8 text-center">
                <h2 class="text-white mt-0">Quiénes somos</h2>
                <hr class="divider divider-light" />
                <p class="text-white-75 mb-4">
                    Despertando del Autismo es una organización sin fines de lucro dedicada a mejorar la calidad de
                    vida de las personas con condiciones del espectro autista y de sus familias, a través de la
                    concientización, capacitación, intervención, empatía y respeto.
                </p>
                <p class="text-white-75 mb-4">
                    Estamos convencidos de que podemos contruir entre todos una sociedad más amable y respetuosa
                    de la diversidad.
                </p>
                <!-- <a class="btn btn-light btn-xl" href="https://www.instagram.com/despertandodelautismo/?hl=es">Instagram</a> -->
            </div>
        </div>
    </div>
</section>