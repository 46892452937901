import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { IEventos } from '../slider-gallery/slider-gallery.component';
declare var SimpleLightbox: any;

@Component({
  selector: 'app-slider-images',
  templateUrl: './slider-images.component.html',
  styleUrls: ['./slider-images.component.scss']
})
export class SliderImagesComponent {
  /**
   *
   */
  @Input() path: any = null;
  lstFiles: any[] = [];
  constructor() {
    
  }
  ngOnInit(): void {
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes) {
      if (changes.path) {
        if (changes.path.currentValue) {
          this.lstFiles = changes.path.currentValue;
        }
      }
    }
  }

}
