<!-- Photo Grid -->
<div class="row" style="display: flex; justify-content: center; width: 98%;" id="galeryCom">
    <div class="column" *ngFor="let item of lstFiles">
        <app-image-ind [ruta]="item"></app-image-ind>
    </div>
    <!--
<a href="https://www.youtube.com/watch?v=k6mFF3VmVAs" class="media" data-toggle="lightbox"
            data-gallery="mixedgallery">
            <img src="https://i1.ytimg.com/vi/yP11r5n5RNg/mqdefault.jpg" style="width:100%">
        </a>

    <a href="https://www.youtube.com/watch?v=k6mFF3VmVAs" class="media" data-toggle="lightbox"
            data-gallery="mixedgallery">
            <img src="https://i1.ytimg.com/vi/yP11r5n5RNg/mqdefault.jpg" style="width:100%">
        </a>
-->
    <!-- <div class="column">
        <a href="https://unsplash.it/1200/768.jpg?image=257" data-toggle="lightbox" data-gallery="mixedgallery"
            class="media">
            <img src="https://unsplash.it/600.jpg?image=257" style="width:100%">
        </a>

        <img src="assets/img/33.png" style="width:100%">
        <img src="assets/img/222.jpg" style="width:100%">
        <img src="assets/img/portfolio/7.jpg" style="width:100%">
        <img src="assets/img/bg-masthead.jpg" style="width:100%">

        <img src="assets/img/bg-masthead_p.jpg" style="width:100%">


    </div>
    <div class="column">
        <a href="https://vimeo.com/80629469" data-remote="https://player.vimeo.com/video/80629469"
            data-toggle="lightbox" data-gallery="mixedgallery" class=" media">
            <img src="https://i.vimeocdn.com/video/80629469_640.png" style="width:100%">
        </a>

        <a href="https://www.youtube.com/watch?v=k6mFF3VmVAs" class="media" data-toggle="lightbox"
            data-gallery="mixedgallery">
            <img src="https://i1.ytimg.com/vi/yP11r5n5RNg/mqdefault.jpg" style="width:100%">
        </a>
        <img src="https://unsplash.it/600.jpg?image=257" style="width:100%">
        <img src="https://i.vimeocdn.com/video/80629469_640" style="width:100%">
        <a href="https://www.youtube.com/watch?v=k6mFF3VmVAs" class="media" data-toggle="lightbox"
            data-gallery="mixedgallery">
            <img src="https://i1.ytimg.com/vi/yP11r5n5RNg/mqdefault.jpg" style="width:100%">
        </a>
        <img src="https://unsplash.it/600.jpg?image=257" style="width:100%">
        <img src="https://i.vimeocdn.com/video/80629469_640" style="width:100%">

    </div> -->
</div>