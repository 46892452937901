import { AfterViewChecked, AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DocumentsService } from 'src/app/services/documents.service';


declare var SimpleLightbox: any;

@Component({
  selector: 'app-image-ind',
  templateUrl: './image-ind.component.html',
  styleUrls: ['./image-ind.component.scss']
})
export class ImageIndComponent implements OnInit, AfterViewInit, AfterViewChecked {
  /**
   *
   */
  @Input() ruta: string = "";
  link: string = "";
  uuidImg: string = "";
  constructor(
    private _s3: DocumentsService
  ) {

  }
  ngOnInit(): void {

    if (this.ruta !== "" && this.ruta !== null && this.ruta !== undefined) {
      this.obtenerLink();
    }
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.

  }
  obtenerLink() {
    this._s3.getLink(this.ruta).subscribe({
      next: data => {
        // console.log(data);
        this.link = data.link;
        let guid = this.uuidv4();
        this.uuidImg = `lao${guid}`;
        setTimeout(() => {
          new SimpleLightbox({
            elements: `#galeryCom a.${this.uuidImg}`
          });
        }, 300);
        
      },
      error: data => {
        console.log(data);
      }
    });
  }
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
      .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
  }
}
