<div id="team">
    <div class="container-fluid p-0">
        <div class="row g-0">
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/1.jpg" title="Conoce al equipo">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/1.png" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50">Despertando del autismo</div>
                        <div class="project-name">Conoce al equipo</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/2.jpg" title="América">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/2.png" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50"></div>
                        <div class="project-name">América</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/3.jpg" title="Korina">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/3.png" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50"></div>
                        <div class="project-name">Korina</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/4.jpg" title="Jazz">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/4.png" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50"></div>
                        <div class="project-name">Jazz</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/5.jpg" title="Blanca">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/5.png" alt="..." />
                    <div class="portfolio-box-caption">
                        <div class="project-category text-white-50"></div>
                        <div class="project-name">Blanca</div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-sm-6">
                <a class="portfolio-box" href="assets/img/portfolio/fullsize/6.jpg" title="Despertando del autismo">
                    <img class="img-fluid" src="assets/img/portfolio/thumbnails/6.png" alt="..." />
                    <div class="portfolio-box-caption p-3">
                        <div class="project-category text-white-50">conciencia, empatía y</div>
                        <div class="project-name">respeto</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>