<section class="page-section bg-dark text-white">
    <div class="container px-4 px-lg-5 text-center">
        <h2 class="mb-4">Redes sociales</h2>
        <a href="https://www.facebook.com/EnCajemeJuntosPorElAutismo" target="_blank">
            <img src="assets/img/socialMedia/facebook.fw.png" />
        </a>
        <a href="https://www.instagram.com/despertandodelautismo/" target="_blank">
            <img src="assets/img/socialMedia/instagram.fw.png" />
        </a>
        <a href="https://www.tiktok.com/@jazzcor2?_t=8fw1aShbEYA&_r=1" target="_blank">
            <img src="assets/img/socialMedia/tiktok.fw.png" />
        </a>
        <a href="https://www.youtube.com/@DespertandodelAutismo" target="_blank">
            <img src="assets/img/socialMedia/youtube.fw.png" />
        </a>
        <a href="https://wa.me/6441114863?text=Hola%2C%20me%20gustar%C3%ADa%20m%C3%A1s%20informaci%C3%B3n" target="_blank">
            <img src="assets/img/socialMedia/whatsapp.fw.png" />
        </a>
    </div>
</section>