import { Component, OnInit } from '@angular/core';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-slider-gallery',
  templateUrl: './slider-gallery.component.html',
  styleUrls: ['./slider-gallery.component.scss']
})
export class SliderGalleryComponent implements OnInit {
  /**
   *
   */
  // myControl = new FormControl();
  lstCarpetas: any[] = [];
  objPath: any = null;
  txtDescripcion: string = "";
  constructor(
    private _s3: DocumentsService
  ) {

  }
  ngOnInit(): void {
    this.getDataFechas();
  }
  seleccionCambiada(ev: any) {
    let value = ev.target.value;
    this.getImagesDay(value);
  }
  getDataFechas() {
    this._s3.GetList().subscribe({
      next: data => {
        data.forEach(element => {
          this.lstCarpetas.push(element.split("/")[2]);
        });
      }, error: data => {
        console.log(data);
      }
    });
  }
  getImagesDay(date: string) {
    this._s3.getImages(date).subscribe({
      next: data => {
        // console.log(data);
        this.objPath = data.filter(x => !x.includes(".txt"));
        if (data.filter(x => x.includes(".txt")).length > 0)
          this.obtenerLinkTexto(data.filter(x => x.includes(".txt"))[0]);
      },
      error: data => {
        console.log(data);
      }
    });
  }
  obtenerLinkTexto(path: string) {
    this._s3.getLink(path).subscribe({
      next: data => {
        // console.log(data.link);
        this.obtenerTextoDesdeEnlace(data.link);
      },
      error: data => {
        console.log(data);
      }
    });
  }
  obtenerTextoDesdeEnlace(enlace) {
    fetch(enlace)
      .then(response => {
        if (!response.ok) {
          throw new Error('No se pudo obtener el archivo de texto');
        }
        return response.text();
      })
      .then(texto => {
        // console.log('Contenido del archivo de texto:', texto);
        // Aquí puedes hacer lo que quieras con el contenido del archivo de texto
        this.txtDescripcion = texto;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
}
export interface IEventos {
  event: IEvento;
}
export interface IEvento {
  date: string;
  descripcion: string;
}