
<app-presentation></app-presentation>

<app-about></app-about>

<app-we-do></app-we-do>

<app-team></app-team>

<app-social-media></app-social-media>

<app-contact></app-contact>

<app-footer></app-footer>
